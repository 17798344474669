
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './Join.css'


const Join = () => {
    const form = useRef();
    
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_fwjxb6n', 'template_ztov6bt', form.current, '5VLAPldj-7m0r3nSt')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text); 
          });
      };
  return (
    <div className='Join' id='join-us '>
        <div className="left-j">
            <hr/>
            <div>
            <span className='stroke-text'>READY TO </span>   
            <span>LEVE UP YOUR </span>
            </div>
               <div>
               <span>YOUR BODY </span>
                <span className='stroke-text'>WITH US? </span>
               </div>
        </div>
        <div className="right-j" name= 'user_email 'placeholder='enter your name'>
  
            <form ref={form} className='email-container' onSubmit={sendEmail}>
                <input type="email" name='user_email' placeholder='Enter your Email' />
                <button className='btn btn-j'>Join Now </button>
            </form>
        </div>
    </div>
  )
}

export default Join 