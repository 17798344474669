import React from 'react'
import hero_image from '../assets/hero_image.png'
import hero_image_back from '../assets/hero_image_back.png'
import Heart from '../assets/heart.png'
import Calories from '../assets/calories.png'
import Header from '../Header/Header'
import './Hero.css'
import CountUp from 'react-countup';
import { motion } from "framer-motion"


const hero = () => {
  const transition = {
    type:'spring', duration:3
  }
  const mobile = window.innerWidth<=768 ? true: false;
  return (
    <div className='hero' id='home'>
       <div className="blur hero-blur"></div>
        <div className="left-h">
            <Header/>
            {/* the best  ad*/}
        <div className="the-best-ad">
            <motion.div 
             initial ={{left:mobile? '168px': '238px'}}
             whileInView ={{left:'8px'}}
             transition = {{...transition, type:'tween'}}
             ></motion.div>
            <span>The Best Fitness Club in the Town</span>
        </div>
        {/*Hero Heading */}
        <div className="hero-text">
            <div>
                <span className='stroke-text'>Shape </span>
                <span>Your </span>
                </div>
                <div>
                    <span>Ideal body</span>
                    </div>
                    <div>
                        <span> in here we will help  you to shape and build your ideal body and live up your life to fullest

                        </span>
                       
                    </div>
        </div>
        {/* figures */}
        <div className="figures">
            <div>
                <span>
                  <CountUp end={140} start ={100} delay ='4' prefix ='+'/>
                </span>
                <span>expert coaches</span>
            </div>
            <div>
                <span> <CountUp end={978} start ={800} delay ='4' prefix ='+'/></span>
                <span>memebers joined</span>
            </div>
            <div>
                <span> <CountUp end={50} start ={0} delay ='4' prefix ='+'/></span>
                <span>fitness programs</span>
            </div>
        </div>
          {/* hero buttons */}
          <div className="hero-button">
            <button className="btn">Get Started</button>
                <button className="btn">Learn More</button>
          </div>
        </div>
        <div className="right-h">
          <button className="btn">Join Now</button>
          <motion.div
          initial ={{right:'-1rem'}}
          whileInView ={{right:'8rem'}}
          transition = {{...transition,type:'tween'}}
           className="heart-rate">
            <img src={Heart} alt="" />
            <span>Heart Rate</span>
            <span>116 bpm</span>
          </motion.div>
          {/* hero images */}
          <img src={hero_image} alt="" className='hero_image' />
          <motion.img 
          initial={{right:'20rem'}}
          whileInView={{left:'-14.5rem'}}
          transition={{...transition,type:'tween'}}
          src={hero_image_back} alt=""  className='hero_image_back'/>
          {/* calories */}
          <motion.div 
          initial ={{right:'32rem'}}
             whileInView ={{left:'-25rem'}}
             transition = {{...transition,type:'tween'}}
          className="calories">
            <img src={Calories} alt="" />
            <div>
            <span>Calories Burned</span>
            <span>220 kcal</span>
            </div>
            
          </motion.div>
        </div>
    </div>
  )
}

export default hero